export const sanitizeTimeInDate = date =>
  new Date(new Date(date).setHours(0, 0, 0, 0));

const DEFAULT_DAY_BUFFER = 2;

const addHoliday = (
  currDate,
  selectedHolidays,
  goForward,
  result = [],
  holidayBuffer
) => {
  let isHoliday =
    selectedHolidays.includes(currDate.toISOString()) ||
    [0, 6].includes(currDate.getDay());

  const nextDate = sanitizeTimeInDate(
    new Date(
      currDate.getYear() + 1900,
      currDate.getMonth(),
      goForward ? currDate.getDate() + 1 : currDate.getDate() - 1
    )
  );

  if (isHoliday) {
    return addHoliday(
      nextDate,
      selectedHolidays,
      goForward,
      [...result, currDate.toISOString()],
      DEFAULT_DAY_BUFFER
    );
  } else {
    let buffer = [];

    if (holidayBuffer > 0) {
      buffer = addHoliday(
        nextDate,
        selectedHolidays,
        goForward,
        [currDate.toISOString()],
        holidayBuffer - 1
      );

      if (buffer.length > DEFAULT_DAY_BUFFER) {
        return [...result, ...buffer];
      }

      return [...result, currDate.toISOString()];
    } else {
      return result;
    }
  }
};

const findHolidayChunk = (
  currDate,
  selectedHolidays,
  availedHolidays,
  usedHolidays,
  goForward
) => {
  currDate = new Date(currDate);

  const leaveDays = addHoliday(
    currDate,
    selectedHolidays,
    goForward,
    [],
    DEFAULT_DAY_BUFFER
  );

  return [...new Set(leaveDays)];
};

export const calculateHolidayPlan = (selectedHolidays = []) => {
  const usedHolidays = new Set();
  selectedHolidays = selectedHolidays
    .sort()
    .map(date => new Date(date).toISOString());

  let holidayChunks = [];
  selectedHolidays.forEach(holidayDate => {
    if (usedHolidays.has(holidayDate)) {
      return;
    }

    const forwards = findHolidayChunk(
      holidayDate,
      selectedHolidays,
      2,
      usedHolidays,
      true
    );
    forwards.forEach(date => usedHolidays.add(date));

    const backwards = findHolidayChunk(
      holidayDate,
      selectedHolidays,
      2,
      usedHolidays,
      false
    );
    backwards.forEach(date => usedHolidays.add(date));

    const chunk = [...new Set([...backwards, ...forwards])]
      .sort()
      .slice(1)
      .slice(0, -1);

    holidayChunks.push(chunk);
  });

  return holidayChunks;
};
