import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Calendar from "react-calendar-material";

import ChevronLeft from "../images/chevron-left.svg";
import ChevronRight from "../images/chevron-right.svg";

const CalendarStyled = styled.div`
  padding: 1rem 0;
`;

const CalendarHeader = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr 2rem 2rem;
  grid-template-areas: "header gap leftBtn rightBtn";
  justify-content: space-between;
  align-items: center;
  margin: 0 auto 1.5rem;
  max-width: 500px;

  .cal-header {
    padding: 0 1rem;
    grid-area: header;
    text-align: center;
    font-size: 1.4rem;
    user-select: none;
  }
  .left-btn {
    height: 1.5rem;
    width: 1.5rem;
    grid-area: leftBtn;
  }
  .right-btn {
    height: 1.5rem;
    width: 1.5rem;
    grid-area: rightBtn;
  }
`;

const CalendarBody = styled.div`
  position: relative;
`;
const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, minmax(3.5rem, min-content));
  grid-auto-rows: 3.5rem;
  justify-content: center;
  text-align: center;
`;

const CalendarWeekHeader = styled(CalendarGrid)`
  span {
    color: #c3c3c3;
    font-size: 0.8rem;
    margin-bottom: 1rem;
    user-select: none;
  }
`;

const CalendarDaysGrid = styled(CalendarGrid)`
  > div {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    user-select: none;
    padding: 0.2rem 0.5rem;
    margin-bottom: 0.5rem;

    &:first-child {
      grid-column-start: ${props => props.startDayOfMonth + 1};
    }

    span {
      padding: 0.2rem 0.8rem;
      border-radius: 0.6rem;
      transition: all 0.3s ease-in-out;
    }

    &:not(.weekend) {
      &.active,
      &:hover {
        span {
          background-color: var(--primary-color);
          color: white;
        }
      }
    }

    &.day-selected-editable {
      border-bottom: 3px solid orangered;
    }

    &.day-selected {
      background-color: #d8d8d8;
    }

    &.day-selected-active {
      /* background-color: aquamarine; */
      background-color: #ff450057;
    }

    &.weekend {
      color: tomato;
      cursor: default;
      opacity: 0.7;
    }
  }
`;
const todayDate = new Date();

const MyCalendar = props => {
  const { customClasses } = props;
  const [selectedMonth, setSelectedMonth] = useState(todayDate.getMonth());
  const [selectedYear, setSelectedYear] = useState(todayDate.getFullYear());
  const [selectedDate, setSelectedDate] = useState(todayDate.getDate());

  const startDayOfMonth = new Date(selectedYear, selectedMonth, 1).getDay();
  const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();

  const incrementMonth = () => {
    setSelectedMonth(prevMonthState => prevMonthState + 1);
  };
  const decrementMonth = () => {
    setSelectedMonth(prevMonthState => prevMonthState - 1);
  };

  const headerCaption = new Date(
    selectedYear,
    selectedMonth,
    selectedDate
  ).toLocaleDateString(
    (typeof navigator !== `undefined` && navigator.language) || "en-US",
    {
      month: "short",
      year: "numeric"
    }
  );

  const getClassesOnDate = date => {
    let classes = new Set();
    const parsedDate = new Date(selectedYear, selectedMonth, date);

    if (selectedDate == date) {
      classes.add("active");
    }

    if ([0, 6].includes(parsedDate.getDay())) {
      classes.add("weekend");
    }

    if (customClasses && !!customClasses.length) {
      customClasses.forEach(customClass => {
        if (customClass.dates.includes(parsedDate.toISOString())) {
          classes.add(customClass.className);
        }
      });
    }

    return [...classes].join(" ");
  };

  const handleDateClick = date => {
    const parsedDate = new Date(selectedYear, selectedMonth, date);
    if ([0, 6].includes(parsedDate.getDay())) {
      return;
    }

    setSelectedDate(date);

    props.onDateClick && props.onDateClick(parsedDate);
  };

  useEffect(() => {
    setSelectedMonth(props.activeMonth);
  }, [props.activeMonth]);

  useEffect(() => {
    props.onMonthChange && props.onMonthChange(selectedMonth);
  }, [selectedMonth]);

  return (
    <CalendarStyled>
      <CalendarHeader>
        {selectedMonth > 0 && (
          <ChevronLeft onClick={decrementMonth} className="left-btn" />
        )}
        <span className="cal-header">{headerCaption}</span>
        {selectedMonth < 11 && (
          <ChevronRight onClick={incrementMonth} className="right-btn" />
        )}
      </CalendarHeader>
      <CalendarBody>
        <CalendarWeekHeader>
          <span>S</span>
          <span>M</span>
          <span>T</span>
          <span>W</span>
          <span>Th</span>
          <span>F</span>
          <span>Sat</span>
        </CalendarWeekHeader>
        <CalendarDaysGrid startDayOfMonth={startDayOfMonth}>
          {[...new Array(daysInMonth).fill(true).keys()].map(day => (
            <div
              role="button"
              className={getClassesOnDate(day + 1)}
              key={day}
              onClick={() => handleDateClick(day + 1)}
            >
              <span>{day + 1}</span>
            </div>
          ))}
        </CalendarDaysGrid>
      </CalendarBody>
    </CalendarStyled>
  );
};

export default MyCalendar;
