import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useFirebase } from "gatsby-plugin-firebase";

import Layout from "../components/layout";
import { Container } from "../components/layoutComponents";
import SEO from "../components/seo";

import { calculateHolidayPlan, sanitizeTimeInDate } from "../utils";
import Calendar from "../components/Calendar";

import CloseIcon from "../images/x.svg";
import { Button } from "../components/Button";
import windowCheck from "../components/windowCheck";

const holidayCache = windowCheck() && window.localStorage.getItem("holidays");
const defaultHolidays = (holidayCache && JSON.parse(holidayCache)) || [];

const availCountCache =
  windowCheck() && window.localStorage.getItem("leaves-availed");
const defaultAvailCounts =
  (availCountCache && JSON.parse(availCountCache)) || 1;

const ContainerGrid = styled.div`
  display: grid;
  grid-template-columns: min-content minmax(min-content, 600px);
  grid-template-areas: "calendar details";
  grid-gap: 2rem;
  justify-content: center;
  margin: 2rem 0;
  align-items: flex-start;

  @media only screen and (max-device-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "details"
      "calendar";
  }

  .details-wrap {
    grid-area: details;
    height: 70vh;
    overflow-y: scroll;
    position: relative;

    @media only screen and (max-device-width: 768px) {
      height: min-content;
      overflow-y: unset;
    }
  }

  .calendar-wrap {
    grid-area: calendar;
    transition: all 0.3s ease-in-out;
    border-radius: 0.6rem;
    position: relative;

    &:before,
    &:after {
      content: "";
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      border-radius: 0.6rem;
      z-index: -1;
    }
    &:before {
      box-shadow: -6px -6px 16px #fff;
    }
    &:after {
      box-shadow: 6px 6px 16px #c1c1c1a1;
    }
  }
`;

const Heading = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 4rem;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: var(--background-color);
  padding-bottom: 1rem;

  h3 {
    margin: 0.3rem 0;
  }

  .subtitle {
    opacity: 0.7;
    font-size: 0.9rem;
  }
`;

const LeaveList = styled.ul`
  margin: 0;
  list-style: none;

  li {
    padding: 0.5rem 1rem;
    border-radius: 0.6rem;
    background-color: #f3f3f3;

    display: grid;
    grid-gap: 1rem;
    align-items: center;
    grid-template-columns: 5rem min-content;
    justify-content: space-between;

    button {
      background-color: transparent;
      padding: 0 0.5rem;
      line-height: 1.5rem;
      border: none;
      outline: none;
      color: orangered;
      display: inline-flex;
      vertical-align: middle;
      cursor: pointer;
    }
  }

  @media only screen and (max-device-width: 768px) {
    display: grid;
    grid-gap: 1rem;
    grid-auto-flow: column;
    width: 95vw;
    overflow-x: scroll;

    li {
      margin: 0;
    }
  }
`;

const LeaveResults = styled.ul`
  margin: 0;
  padding: 0;

  li {
    display: grid;
    grid-template-columns: 7rem 1fr min-content 1fr;
    grid-gap: 2rem;
    padding: 0.5rem 1rem;
    border-radius: 0.6rem;
    background-color: #f3f3f3;
    font-size: 1rem;
    user-select: none;
    cursor: pointer;

    .leave-length {
      color: var(--primary-color);
      padding-right: 1rem;

      .additional {
        font-size: 0.7rem;
        opacity: 0.8;
      }
    }

    @media only screen and (max-device-width: 768px) {
      grid-gap: 0.5rem;
    }
  }

  @media only screen and (max-device-width: 768px) {
    display: grid;
    grid-gap: 1rem;
    grid-auto-flow: column;
    width: 95vw;
    overflow-x: scroll;
  }
`;

const EmptyCard = styled.div`
  font-size: 0.8rem;
  font-style: italic;
`;

const formatDate = date =>
  new Date(date).toLocaleDateString(
    (typeof navigator !== `undefined` && navigator.language) || "en-US",
    {
      month: "short",
      day: "numeric"
    }
  );

const IndexPage = () => {
  const [selectedHolidays, setSelectedHolidays] = useState(defaultHolidays);
  const [availedHolidays, setAvailedHolidays] = useState(defaultAvailCounts);
  const [activeHolidayPeriod, setActiveHolidayPeriod] = useState([]);
  const [activeMonth, setActiveMonth] = useState(new Date().getMonth());
  const [editMode, setEditMode] = useState(
    !defaultHolidays || !defaultHolidays.length
  );

  useFirebase(firebase => {
    firebase.analytics().logEvent("USER_ENTRY");
  }, []);

  useFirebase(
    firebase => {
      firebase.analytics().logEvent("OPENED_EDIT_MODE");
    },
    [editMode]
  );

  useEffect(() => {
    windowCheck() &&
      window.localStorage.setItem("holidays", JSON.stringify(selectedHolidays));
  }, [selectedHolidays]);

  useEffect(() => {
    windowCheck() &&
      window.localStorage.setItem("leaves-availed", availedHolidays);
  }, [availedHolidays]);

  let leaveGroups = React.useMemo(() => {
    const lgs = calculateHolidayPlan(selectedHolidays, availedHolidays);
    return lgs.map(leaves => ({
      leaves,
      additional: leaves.filter(
        l =>
          !selectedHolidays.includes(l) &&
          ![0, 6].includes(new Date(l).getDay())
      ).length
    }));
  }, [selectedHolidays, availedHolidays]);

  leaveGroups = leaveGroups.sort((a, b) => {
    if (a.leaves.length < b.leaves.length) {
      return 1;
    }
    if (a.leaves.length > b.leaves.length) {
      return -1;
    }

    return 0;
  });

  const handleDateClick = date => {
    if (!editMode) {
      return;
    }

    setSelectedHolidays(prevDates => {
      prevDates = new Set(prevDates);
      date = date.toISOString();

      if (prevDates.has(date)) {
        prevDates.delete(date);
      } else {
        prevDates.add(date);
      }

      return [...prevDates];
    });
  };

  const handleLeaveResultClick = leaveGroup => {
    try {
      if (!leaveGroup[0]) {
        return;
      }

      let monthMean = leaveGroup.reduce((total, curr) => {
        const key = new Date(curr).getMonth();
        return { ...total, [key]: (total[key] || 0) + 1 };
      }, {});

      monthMean = Object.entries(monthMean).sort((a, b) => {
        if (a[1] < b[1]) {
          return 1;
        }
        if (a[1] > b[1]) {
          return -1;
        }

        return 0;
      });

      const activeMonth = Number(monthMean[0][0]);
      setActiveMonth(activeMonth);
    } catch (error) {
      console.error(error);
    }
  };

  const activeDates = (leaveGroups || []).reduce((a, b) => {
    return [...a, ...(b.leaves || [])];
  }, []);

  return (
    <Layout>
      <Container>
        <SEO
          title="Vacay - Plan your leaves in seconds"
          url="https://vacay.netlify.com/"
          description="Plan your leaves for the year in minutes"
          keywords={[`vacation`, `leaves`, `management`]}
        />

        <ContainerGrid>
          <div className={`calendar-wrap ${editMode ? "editable" : ""}`}>
            <Calendar
              onMonthChange={setActiveMonth}
              activeMonth={activeMonth}
              customClasses={[
                {
                  className: "day-selected-editable",
                  dates: selectedHolidays
                },
                {
                  className: "day-selected-active",
                  dates: activeHolidayPeriod
                },
                ...(!editMode
                  ? [
                      {
                        className: "day-selected",
                        dates: activeDates
                      }
                    ]
                  : [])
              ]}
              onDateClick={handleDateClick}
            />
          </div>
          <div className="details-wrap">
            {editMode ? (
              <Heading className="details-header">
                <div>
                  <h3>Assigned Holidays</h3>
                  <span className="subtitle">
                    Click on the dates to select the assigned holidays
                  </span>
                </div>
                <Button onClick={() => setEditMode(false)}>Save Leaves</Button>
              </Heading>
            ) : (
              <Heading className="details-header">
                <div>
                  <h3>Vacation Plans</h3>
                  <span className="subtitle">Checkout all the vacations</span>
                </div>
                <Button onClick={() => setEditMode(true)}>
                  Add your leaves
                </Button>
              </Heading>
            )}
            {!!editMode && (
              <LeaveList>
                {selectedHolidays &&
                  selectedHolidays.map(date => (
                    <li key={date}>
                      <span>{formatDate(date)}</span>
                      <button onClick={() => handleDateClick(new Date(date))}>
                        <CloseIcon />
                      </button>
                    </li>
                  ))}

                {selectedHolidays && !selectedHolidays.length && (
                  <EmptyCard>You haven't added any leaves yet.</EmptyCard>
                )}
              </LeaveList>
            )}
            {!editMode && (
              <LeaveResults>
                {leaveGroups &&
                  leaveGroups.map((lg, index) => (
                    <li
                      key={index}
                      onMouseEnter={() => setActiveHolidayPeriod(lg.leaves)}
                      onClick={() => handleLeaveResultClick(lg.leaves)}
                      onMouseLeave={() => setActiveHolidayPeriod([])}
                    >
                      <span className="leave-length">
                        {lg.leaves.length}{" "}
                        {lg.additional ? (
                          <span className="additional">/ {lg.additional}</span>
                        ) : (
                          ""
                        )}{" "}
                        <span className="additional">leaves</span>
                      </span>
                      <span>{formatDate(lg.leaves[0])}</span>
                      <span>-</span>
                      <span>{formatDate(lg.leaves[lg.leaves.length - 1])}</span>
                    </li>
                  ))}

                {leaveGroups && !leaveGroups.length && (
                  <EmptyCard>You haven't added any leaves yet.</EmptyCard>
                )}
              </LeaveResults>
            )}
          </div>
        </ContainerGrid>
      </Container>
    </Layout>
  );
};

export default IndexPage;
